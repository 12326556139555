import {
  getClosureList,
  getClosureDetails,
  putClosure,
  postClosure,
  deleteClosure,
  putClosureApprove,
} from "api";

const list = (params) => getClosureList(params);
const show = (id) => getClosureDetails(id);
const update = (id, data) => putClosure(id, data);
const store = (data) => postClosure(data);
const destroy = (id) => deleteClosure(id);
const approve = (id) => putClosureApprove(id);

export const closureService = {
  list,
  show,
  update,
  store,
  destroy,
  approve,
};
