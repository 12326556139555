import api from "./index";

const prefix = "transaction";
const getTransactionList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")}`
  );
const getTransactionDetails = (id) => api.get(`${prefix}/${id}`);
const postTransaction = (data) => api.post(prefix, data);
const putTransaction = (id, data) => api.put(`${prefix}/${id}`, data);
const postTransactionReport = (data) => api.post(`${prefix}/report`, data);
const deleteTransaction = (id, data = undefined) =>
  api.delete(`${prefix}/${id}`, { data });
const postTransactionBatch = (data) => api.post(`${prefix}/batch`, data);
const postTransactionImport = (data) =>
  api.post(`${prefix}/import`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
const getTransactionMonth = (query) =>
  api.get(
    `${prefix}/month?${Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")}`
  );

const postFinancialConsolidatedReport = (data) => {
  return api.post(`financial/report/consolidated`, data);
};

const postFinancialAnnualReport = (data) => {
  return api.post(`financial/report/annual`, data);
};
const getTransactionPersonList = (search) =>
  api.get(`${prefix}/person-list?search=${search}`);

const postFinancialBalanceReport = (data) => {
  return api.post(`financial/report/balance`, data);
};

const postFinancialTithersReport = (data) => {
  return api.post(`financial/report/tithers`, data);
};

const putTransactionConfirm = (id, data) =>
  api.put(`${prefix}/${id}/confirm`, data);

export {
  getTransactionList,
  getTransactionDetails,
  postTransaction,
  putTransaction,
  deleteTransaction,
  postTransactionReport,
  postTransactionBatch,
  postTransactionImport,
  getTransactionMonth,
  postFinancialConsolidatedReport,
  postFinancialAnnualReport,
  getTransactionPersonList,
  postFinancialBalanceReport,
  postFinancialTithersReport,
  putTransactionConfirm,
};
